@use '~@sbt-web/ui/common/css/variables';

.magazine {
  display: flex;
  flex-wrap: wrap;
}

.image {
  border-radius: var(--sbt-corner-md);
}

.text {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  padding-left: 16px;

  .tag {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin: 0;
  }

  .title {
    margin-top: var(--sbt-spacing-xs);
  }
}

/* Only for mobile */
@media (max-width: variables.$mobile_max_width) {
  .image {
    max-width: 100%;
  }
}

/* From mobile through to tablet */
@media (max-width: variables.$tablet_max_width) {
  .image {
    margin-bottom: var(--sbt-spacing-sm);
  }
}

/* From tablet and upwards */
@media (min-width: variables.$tablet_min_width) {
  .image {
    max-width: 55%;
  }

  .text {
    max-width: 45%;
  }
}
