@use '~@sbt-web/ui/common/css/variables' as v;

$desktop-placement-h: 250px;
$desktop-placement-w: 970px;

.wrapper {
  width: $desktop-placement-w + 2px;
  height: $desktop-placement-h + 2px;
  margin: var(--sbt-layout-md) auto 0 auto;
  border: var(--sbt-border-default);
  overflow: hidden;
  &, .banner, .banner > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: v.$tablet_max_width) {
    display: none;
  }

  .banner {
    width: 100%;
    height: 100%;
    > div:not(div:empty) {
      background-color: var(--sbt-background-default);
      width: 100% !important;
      height: 100% !important;
    }
  }

}
