@use '~@sbt-web/ui/common/css/variables' as v;

.button {
  background-color: var(--sbt-primary-color);

  &:hover {
    background-color: var(--sbt-primary-color-l1);
  }

  &.motori {
    --sbt-primary-color: var(--sbt-accent-blue);
    --sbt-primary-color-l1: #{v.$blueMotoriL1};
    --sbt-primary-color-d1: #{v.$blueMotoriD1};
  }

  &.lavoro {
    --sbt-primary-color: var(--sbt-accent-green);
    --sbt-primary-color-l1: #{v.$greenLavoroL1};
    --sbt-primary-color-d1: #{v.$greenLavoroD1};
  }

  &.market {
    --sbt-primary-color: var(--sbt-accent-yellow);
    --sbt-primary-color-l1: #{v.$yellowMarketL1};
    --sbt-primary-color-d1: #{v.$yellowMarketD1};
  }

  &.immobili {
    --sbt-primary-color: var(--sbt-accent-purple);
    --sbt-primary-color-l1: #{v.$purpleImmobiliL1};
    --sbt-primary-color-d1: #{v.$purpleImmobiliD1};
  }
}

.button-with-label {
  color: var(--sbt-background-default);
  width: 100%;
  margin-top: auto;
  padding: var(--sbt-layout-2xs);
  border-radius: var(--sbt-corner-md);
  text-align: center;
  font-size: var(--sbt-body-text-font-size);
  line-height: var(--sbt-body-text-font-size);
  font-weight: 600;
}

.button-with-icon {
  --sbt-primary-color: var(--sbt-accent-red);
  --sbt-primary-color-l1: #{v.$redCorporateL1};
  --sbt-primary-color-d1: #{v.$redCorporateD1};

  background-color: var(--sbt-primary-color);
  border-radius: var(--sbt-corner-md);
  color: var(--sbt-background-default);
  font-size: var(--sbt-body-text-font-size);
  display: block;
  height: 48px;
  text-align: center;
  width: 100%;

  > img {
    vertical-align: middle;
  }

  &:hover {
    background-color: var(--sbt-primary-color-l1);
  }

  &:active,
  &:focus {
    background-color: var(--sbt-primary-color-d1);
  }

  @media (max-width: v.$mobile_max_width) {
    height: 48px;

    > img {
      display: none;
    }
  }

  @media (min-width: v.$tablet_min_width) {
    > span {
      display: none;
    }
  }

  @media (min-width: v.$desktop_min_width) {
    width: 64px;
    display: block;

    > span {
      display: none;
    }
  }
}
