@use '~@sbt-web/ui/common/css/variables' as v;

.compact-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr) minmax(60px, auto);
  grid-auto-flow: column;
  gap: var(--sbt-spacing-sm);
  align-items: end;
}

.linear-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--sbt-spacing-md);
}

.input-label {
  color: var(--sbt-primary-text-color);
  font-size: v.$secondary_font_size;
  line-height: 20px;
  margin: 0 0 6px;
  display: block;
  font-weight: 600;
}

.input {
  width: 100%;
  height: var(--sbt-input-height-lg);
  border: var(--sbt-border-default);
  border-radius: 4px;
  overflow: hidden;
  appearance: none;
  transition: border-color 0.5s ease-out;
  display: flex;

  &:focus,
  &:hover {
    border-color: var(--sbt-placeholder-text-color);
  }

  input {
    width: 100%;
  }
}

.input-icon {
  background-color: var(--sbt-background-default);
  display: inline-block;
  vertical-align: middle;
  padding: 7px;

  @media (max-width: v.$mobile_max_width) {
    height: 48px;
    width: 48px;
    padding: 12px;
  }
}

/* <CategoriesList /> */
.category-input-wrapper {
  position: relative;
  height: auto;
  overflow-y: hidden;
  border: var(--sbt-border-default);
  border-radius: var(--sbt-spacing-2xs);

  .category-input {
    border: 0;
    margin-bottom: 0;
    .input-inner {
      cursor: pointer;
      text-align: left;
      flex: 1;
    }

    .input-arrow {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.25s ease-out;
      &.input-arrow-rotate {
        transform: rotate(180deg);
      }
    }
  }

  .cat-item-list {
    font-size: var(--sbt-body-text-font-size);
    padding: 9px var(--sbt-spacing-sm);
    color: var(--sbt-primary-text-color);
    line-height: 24px;
    margin: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: left;
    transition: background-color 0.25s ease-in-out;

    &:hover {
      background-color: var(--sbt-background-root);
    }

    &.bold {
      font-weight: 600;
    }
  }

  // Mobile implementation:
  @media (max-width: v.$tablet_max_width) {
    .desktop-category {
      display: none;
    }

    .mobile-category {
      display: block;
    }

    .title-cat-mobile-modal {
      font-size: v.$big1_font_size;
      color: var(--sbt-primary-text-color);
      padding: var(--sbt-spacing-md);
      line-height: 25px;
      font-weight: 600;
      display: block;
      text-align: center;
    }

    .list-mobile-wrapper {
      padding: var(--sbt-spacing-md) var(--sbt-spacing-md) var(--sbt-spacing-xl);
      width: 100%;
      .title-cat {
        display: block;
        font-weight: 600;
        color: var(--sbt-primary-text-color);
      }
      ul {
        padding: 0;
        margin: var(--sbt-spacing-md) 0;
      }
    }
  }

  // Desktop implementation:
  @media (min-width: v.$desktop_min_width) {
    .mobile-category {
      display: none;
    }

    .lt-allCat {
      display: block;
      width: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      transition: max-height 0.25s ease-in-out;
      margin: 0;
      padding: 0;
      max-height: 0;
      list-style-type: none;
      background: var(--sbt-background-default);
      border-radius: 0 0 var(--sbt-corner-md) var(--sbt-corner-md);

      &.lt-allCat-visible {
        max-height: 225px;
      }
    }
  }
}
