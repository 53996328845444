@use '~@sbt-web/ui/common/css/variables';

.discover-subito {
  display: flex;
  justify-content: space-between;
  gap: var(--sbt-spacing-lg);

  .vertical-wrapper {
    flex: 1 1;
    flex-basis: 25%;
    width: 25%;
  }

  @media (max-width: variables.$tablet_max_width) {
    gap: var(--sbt-spacing-md);
  }
  @media (max-width: variables.$mobile_max_width) {
    gap: var(--sbt-spacing-xs);
  }
  @media (max-width: 350px) {
    gap: initial;
  }
}