@use '~@sbt-web/ui/common/css/variables';

.mobileSearchHeader {
  margin-bottom: var(--sbt-spacing-sm);
  text-align: center;

  @media (min-width: variables.$tablet_min_width) {
    display: none;
  }
}
