@use '~@sbt-web/ui/common/css/variables' as v;

.card {
  display: grid;
  border: var(--sbt-border-default);
  border-radius: var(--sbt-corner-lg);
  background-color: var(--sbt-background-default);
  transition: background-color ease-in-out 0.2s;
  &:hover {
    background-color: var(--sbt-background-root);
    text-decoration: none;
  }

  --card-w: 100%;
  --card-h: 158px;
  --thumb-w: 108px;
  --thumb-h: 126px;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
      "thumb info"
      "thumb cta";

  @media (min-width: v.$tablet_min_width) {
    padding: var(--sbt-spacing-md);
    grid-column-gap: var(--sbt-spacing-md);
    &--only-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      .info {
        flex-grow: 2;
      }
      .cta {
        align-self: center;
      }
    }
  }
  @media (max-width: v.$mobile_max_width) {
    padding: var(--sbt-spacing-sm) var(--sbt-spacing-xs);
    grid-column-gap: var(--sbt-spacing-xs);
    --card-h: 152px;
    --thumb-w: 108px;
    --thumb-h: 82px;

    grid-row-gap: var(--sbt-spacing-sm);
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "thumb info"
      "cta cta";

    &--no-cta {
      --card-w: 134px;
      --card-h: 206px;
      padding: var(--sbt-spacing-sm);
      grid-template-columns: 100%;
      grid-template-rows: var(--thumb-h) 1fr;
      grid-template-areas:
          "thumb"
          "info";

    }
  }

  width: var(--card-w);
  height: var(--card-h);

  // Based on the size of the card, on desktop, we display a special UI for the lonely card
  container: card-container / size;
}

.thumbnail {
  grid-area: thumb;
  width: var(--thumb-w);
  height: var(--thumb-h);
}

.info {
  grid-area: info;
  overflow: hidden;
  @media (max-width: v.$mobile_max_width) {
    .card--no-cta & {
      display: flex;
      flex-direction: column;
      .subject {
        margin-bottom: var(--sbt-spacing-3xs);
      }
      .geo {
        flex-grow: 2;
      }
    }
  }
}

.cta {
  grid-area: cta;
  align-self: end;
  justify-self: stretch;
  > a, button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.subject {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.geo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: initial;
  display: flex;
  gap: var(--sbt-spacing-2xs);
}

.price {
  align-self: start;
  @media (max-width: v.$mobile_max_width) {
    line-height: v.$default_line_height;
    font-size: var(--sbt-body-text-font-size);
  }
}

@container card-container (min-width: 700px) {
  .thumbnail {
    --thumb-w: 202px;
  }

  .subject {
    &:global(.body-text) {
      font-size: v.$big2_font_size;
      line-height: v.$default_line_height;
    }
    margin-bottom: var(--sbt-spacing-2xs);
  }
  .geo {
    font-size: v.$secondary_font_size;
    line-height: v.$default_line_height;
  }
  .price {
    margin-top: var(--sbt-spacing-2xs);
    font-size: 24px;
    line-height: 32px;
  }
  .cta {
    width: 200px;
    // make it a ButtonLink 'large'
    > a, button {
      height: var(--sbt-button-height-lg);
      > span {
        font-size: v.$default_font_size !important;
        line-height: v.$default_font_size !important;
      }
    }
  }
}