@use '~@sbt-web/ui/common/css/variables';

/*
 * Remove default fieldset styles.
 */
fieldset,
h1,
h2,
h3,
p {
  border: 0;
  margin: 0;
  padding: 0;
}

// This margin positions the page's content correctly below the header
@media (max-width: variables.$tablet_max_width) {
  main {
    margin-top: var(--sbt-spacing-xl);
  }
}



.standard-container {
  padding: 0 var(--sbt-spacing-lg);
  &--with-bottom-padding {
    padding-bottom: var(--sbt-layout-2xs);
  }
}

.dynamic-atf-container {
  // This has the aim of improve the CLS
  min-height: 400px;
}

@media (min-width: variables.$desktop_min_width) {
  .standard-container {
    margin: 0 auto;
    max-width: variables.$desktop_min_width;
    &--with-bottom-padding {
      padding-bottom: var(--sbt-layout-md);
    }
  }
}

.vertical-block {
  margin-top: var(--sbt-layout-sm);
  @media (min-width: variables.$desktop_min_width) {
    margin-top: var(--sbt-layout-md);
  }
  .title {
    line-height: 28px;
    font-size: variables.$big2_font_size;
    color: var(--sbt-primary-text-color);
    margin-top: var(--sbt-spacing-xs);
    display: block;
  }
  p {
    line-height: 24px;
    margin-top: var(--sbt-spacing-2xs);
    font-size: variables.$default_font_size;
    color: var(--sbt-secondary-text-color);
  }
}

.bold,
strong {
  font-weight: 600;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

button {
  cursor: pointer;
}

button,
input {
  color: #3c4858;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  appearance: none;
}

body.modal-open {
  overflow: hidden;
}

#af-smart-banner ~ #__next div[class^='index-module_header-container'] {
  top: auto;
}
#af-smart-banner {
  z-index: 999 !important;
}

body > div[style='height: 70px;']:first-child {
  transition: height 240ms ease-in-out;
}
