@use '~@sbt-web/ui/common/css/variables.scss' as v;

.wrapper {
  overflow: hidden;
  position: relative;
  background-color: var(--sbt-background-root);
  border: var(--sbt-border-default);
  border-radius: var(--sbt-corner-lg);

  > figure {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    .large-desktop-only {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  // Based on the size of the container we choose the image to display.
  container: thumb-container / size;
}

.large-desktop-only {
  display: none;
}

@container thumb-container (min-width: 200px) {
  .large-desktop-only {
    display: block;
  }
  .cross-image {
    display: none;
  }
}

.placeholder {
  width: var(--sbt-icon-lg);
  height: var(--sbt-icon-lg);
}

.count {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: var(--sbt-corner-sm);
  color: v.$white;
  height: var(--sbt-layout-2xs);
  padding: var(--sbt-spacing-3xs) var(--sbt-spacing-2xs);

  display: flex;
  align-items: center;

  position: absolute;
  left: var(--sbt-spacing-xs);
  bottom: var(--sbt-spacing-xs);

  span {
    font-size: 12px;
    line-height: 18px;
    margin: 2px 0 1px 2px;
  }
}