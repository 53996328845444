@use '~@sbt-web/ui/common/css/variables';

.insert-box {
  border: var(--sbt-border-default);
  border-radius: var(--sbt-corner-md);
}

@media (max-width: variables.$mobile_max_width) {
  .insert-box {
    padding: var(--sbt-spacing-lg);
    position: relative;
  }

  .insert-box-inner {
    margin-bottom: var(--sbt-spacing-2xl);
    p {
      max-width: 180px;
    }
  }

  .insert-box-inner,
  .insert-box-buttonlink {
    position: relative;
  }

  .background-photo img {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%; /* In this case we bind to the relatively-positioned parent */
  }
}

@media (min-width: variables.$tablet_min_width) {
  .insert-box {
    display: flex;
    align-items: center;
    height: 150px; /* This sets the baseline for the photo later */
  }

  .insert-box-inner {
    max-width: 240px;
  }

  .insert-box-buttonlink {
    margin: 0 var(--sbt-spacing-md) 0 auto;
  }

  .background-photo {
    height: 100%; /* This is the height of the parent, the div.insert-box */

    img {
      border-top-left-radius: var(--sbt-corner-sm);
      border-bottom-left-radius: var(--sbt-corner-sm);
      height: 100%; /* This is the height of the parent, the picture */
    }
  }
}

@media (min-width: variables.$desktop_min_width) {
  .insert-box-inner {
    max-width: 345px;
  }
}
