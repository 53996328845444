@use '~@sbt-web/ui/common/css/variables';

.logo-mobile {
  display: none;
}

@media (max-width: variables.$tablet_max_width) {
  .logo-mobile {
    display: block;
    text-align: center;
    padding: 0 var(--sbt-spacing-sm) var(--sbt-spacing-xl);
  }
}
