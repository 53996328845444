@use '~@sbt-web/ui/common/css/variables';

.safety_tips_box {
  background-color: var(--sbt-background-root);

  @media (max-width: variables.$mobile_max_width) {
    padding: var(--sbt-spacing-xl) 0 var(--sbt-spacing-2xl)
      var(--sbt-spacing-lg);
  }

  @media (min-width: variables.$tablet_min_width) {
    max-width: calc(variables.$desktop_min_width - (var(--sbt-spacing-lg) * 2));
    padding: var(--sbt-spacing-2xl) 0 var(--sbt-spacing-3xl)
      var(--sbt-spacing-lg);
  }

  @media (max-width: variables.$tablet_max_width) {
    margin: 0 auto;
  }

  @media (min-width: variables.$desktop_min_width) {
    margin: 0 auto;
    padding: var(--sbt-spacing-2xl) var(--sbt-spacing-lg) var(--sbt-spacing-3xl);
  }
}

.cards_box {
  display: flex;
  gap: var(--sbt-spacing-xl);
  flex-flow: row nowrap;
  overflow-x: auto;

  @media (max-width: variables.$tablet_max_width) {
    margin-top: var(--sbt-spacing-lg);
  }

  @media (min-width: variables.$desktop_min_width) {
    margin-top: var(--sbt-spacing-xl);
  }
}

.card {
  background-color: var(--sbt-background-default);
  border-radius: var(--sbt-corner-lg);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  /* Mobile-only */
  @media (max-width: variables.$mobile_max_width) {
    flex: 1 0 80%;
    padding: var(--sbt-spacing-md) var(--sbt-spacing-xs);
  }

  /* Tablet-only */
  @media (min-width: variables.$tablet_min_width) and (max-width: variables.$tablet_max_width) {
    flex: 1 0 40%;
  }

  /* Tablet and desktop */
  @media (min-width: variables.$tablet_min_width) {
    padding: var(--sbt-spacing-lg) var(--sbt-spacing-md);
  }
}

.image {
  align-self: center;
  max-width: 100%;
  height: auto;
}

.title {
  color: var(--sbt-primary-text-color);
  margin: var(--sbt-spacing-sm) 0 var(--sbt-spacing-2xs);

  @media (min-width: variables.$tablet_min_width) {
    margin-bottom: var(--sbt-spacing-xs);
  }
}

.text {
  color: variables.$redCorporate;
}

.card:hover .text {
  text-decoration: underline;
}
