@use '~@sbt-web/ui/common/css/variables' as v;

.sx-inner {
  min-height: 140px;
  display: flex;
  flex-direction: column;
}

.modal-dx-inner {
  position: relative;
  padding: var(--sbt-spacing-md) var(--sbt-spacing-md) var(--sbt-spacing-xl);
  > form {
    height: 100%;
  }
}

.modal {
  &.motori {
    .sx-inner {
      background-color: v.$blueMotoriL3;
    }

    :global(.close-x-modal) {
      fill: v.$blueMotori;
    }
  }

  &.lavoro {
    .sx-inner {
      background-color: v.$greenLavoroL3;
    }

    :global(.close-x-modal) {
      fill: v.$greenLavoro;
    }
  }

  &.market {
    .sx-inner {
      background-color: v.$yellowMarketL3;
    }

    :global(.close-x-modal) {
      fill: v.$yellowMarket;
    }
  }

  &.immobili {
    .sx-inner {
      background-color: v.$purpleImmobiliL3;
    }

    :global(.close-x-modal) {
      fill: v.$purpleImmobili;
    }
  }
}

@media (min-width: v.$desktop_min_width) {
  .sx-inner {
    width: 240px;
    flex: 0 1 240px;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: var(--sbt-spacing-xs) 0 0 var(--sbt-spacing-xs);
    padding: var(--sbt-spacing-2xl) var(--sbt-spacing-lg);
  }
  .modal-dx-inner {
    flex: auto;
    position: relative;
    padding: 68px 60px 50px 38px;
  }
}

@media (max-width: v.$tablet_max_width) {
  .sx-inner {
    height: 140px;
    padding: var(--sbt-spacing-xl);
    align-items: flex-start;
    justify-content: flex-end;
  }

  .modal-dx-inner {
    flex: 1 0 auto;
    padding-top: var(--sbt-spacing-2xl);
    padding-right: var(--sbt-spacing-md);
    padding-bottom: var(--sbt-spacing-2xl);
    padding-left: var(--sbt-spacing-xl);
    overflow: auto;
    position: relative;
  }
}
