@use '~@sbt-web/ui/common/css/variables';

.tutto-subito {
  display: flex;
  justify-content: space-between;
  overflow: auto;
}

.tutto-subito__button {
  display: flex;
  justify-content: center;
}

.tutto-subito__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tutto-subito__actions {
  display: flex;
  align-items: center;
  gap: var(--sbt-spacing-lg);
}

.tutto-subito__content-container {
  position: relative;

  img {
    object-fit: contain;
  }

  a {
    bottom: var(--sbt-spacing-sm);
    position: absolute;
    margin: 0 var(--sbt-spacing-xl);
    width: calc(100% - (2 * var(--sbt-spacing-xl)));
  }
}

@media (max-width: variables.$mobile_max_width) {
  .tutto-subito::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  .tutto-subito__actions {
    align-items: flex-start;
    gap: var(--sbt-spacing-xs);
  }

  .tutto-subito__info {
    min-width: 10em;
  }

  .tutto-subito__content-container {
    position: static;

    picture {
      display: inline-block;
      height: 159px;
      width: 144px;
    }

    img {
      height: 100%;
      width: 100%;
    }

    a {
      margin: var(--sbt-spacing-lg) 0 0 0;
      position: static;
      width: 100%;
    }
  }
}
