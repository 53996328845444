@use '~@sbt-web/ui/common/css/variables' as v;

$arrow-carousel-ads: 'https://assets.subito.it/static/icons/cactus/arrow-right.svg';

.wrapper {
  max-height: 0;
  opacity: 0;
  transition:
          max-height 0.6s ease-in-out,
          opacity 0.2s ease-in-out 0.3s;

  &.ready {
    opacity: 1;
    max-height: 300px;
  }
}

.title {
  margin-bottom: var(--sbt-spacing-md);
  @media (min-width: v.$tablet_min_width) {
    // as headline-5
    font-size: v.$big2_font_size;
    line-height: 28px;
  }
}

.carousel {
  --sbt-carousel-height-desktop: auto;
  --sbt-carousel-height-mobile: auto;

  @media (min-width: v.$desktop_min_width) {
    --swiffy-slider-item-reveal: 0px;
    --swiffy-slider-item-gap: var(--sbt-spacing-lg);
    --swiffy-slider-item-count: 3;
    .with-2 & {
      --swiffy-slider-item-count: 2;
    }
  }
  @media (max-width: v.$tablet_max_width) {
    --swiffy-slider-item-reveal: 4rem;
    --swiffy-slider-item-gap: var(--sbt-spacing-xs);
    --swiffy-slider-item-count: 2;
    .with-2 & {
      --swiffy-slider-item-reveal: 0px;
    }
  }
  @media (max-width: v.$mobile_max_width) {
    --swiffy-slider-item-reveal: 4rem;
    --swiffy-slider-item-gap: var(--sbt-spacing-xs);
    --swiffy-slider-item-count: 1;
    .without-cta & {
      :global(.slider-container) {
        --swiffy-slider-item-width: 134px;
      }
    }
  }

  .with-1 & {
    --swiffy-slider-item-count: 1;
    --swiffy-slider-item-reveal: 0px;
  }

  :global(.slider-nav),
  :global(.slider-nav-next) {
    display: flex;
    align-items: center;
    justify-content: center;
    filter: none;

    &::before {
      width: var(--sbt-icon-lg);
      height: var(--sbt-icon-lg);
      padding: 0;
      background-color: var(--sbt-background-default);
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
    }

    &::after {
      width: var(--sbt-icon-md);
      height: var(--sbt-icon-md);
      background-color: var(--sbt-primary-icon-color);
      mask: url($arrow-carousel-ads);
      -webkit-mask: url($arrow-carousel-ads);
    }
  }

  :global(.slider-nav) {
    &::after {
      transform: rotate(180deg);
    }
  }

  :global(.slider-nav-next) {
    &::after {
      transform: rotate(0deg);
    }
  }
}