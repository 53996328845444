@use '~@sbt-web/ui/common/css/variables';

.internal-links {
  border-top: var(--sbt-border-default);
  padding: var(--sbt-spacing-2xl) 0 0;
  max-width: variables.$desktop_min_width;
  margin: 0 auto var(--sbt-spacing-2xl);
}

.internal-links-title {
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: var(--sbt-spacing-md);
}
