@use '~@sbt-web/ui/common/css/variables.scss';

.searchbar {
  margin-bottom: var(--sbt-spacing-2xl);

  .input-label {
    color: var(--sbt-primary-text-color);
    font-size: variables.$secondary_font_size;
    line-height: 20px;
    margin: 0 0 6px 0;
    display: block;
  }

  .input {
    width: 100%;
    height: 48px;
    border: 1px solid variables.$neutralGrey4;
    border-radius: var(--sbt-corner-md);
    overflow: hidden;
    appearance: none;
    transition: border-color 0.5s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--sbt-spacing-md);
    .arrow-inside {
      background-color: var(--sbt-background-default);
      background-image: url('https://assets.subito.it/static/icons/cactus/arrow-down.svg');
      background-position: right 0.7em top 50%;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      font-size: 16px;
    }

    .category-opener {
      text-align: left;
    }
  }
  .input:hover,
  .input:focus,
  .input:focus-within {
    border-color: variables.$neutralGrey3;
  }
  .input.focused {
    border-color: variables.$neutralGrey3;
  }

  .input-icon {
    background-color: var(--sbt-background-default);
    display: inline-block;
    height: 38px;
    padding: 7px;
    vertical-align: middle;
    width: 38px;
  }

  .input-inner {
    flex: 1;
    input[type='text'],
    .search-input {
      width: 100%;
      height: 38px;
    }
    span {
      color: var(--sbt-primary-text-color);
      font-size: variables.$secondary_font_size;
      line-height: 20px;
      padding: 9px 9px 9px 0;
      display: inline-block;
      width: 100%;
      background: variables.$white;
    }
    > input {
      color: variables.$neutralGrey3;
    }
  }
}
.searchbar-col,
.searchbar-col-lens {
  width: 100%;
}
.button-icon-lens {
  background-color: variables.$redCorporate;
  border-radius: var(--sbt-corner-md);
  color: variables.$white;
  font-size: variables.$default_font_size;
  display: block;
  height: 48px;
  text-align: center;
  width: 100%;
  > img {
    vertical-align: middle;
  }
}
.button-icon-lens:hover {
  background-color: variables.$redCorporateL1;
}
.button-icon-lens:active,
.button-icon-lens:focus {
  background-color: variables.$redCorporateD1;
}
.geo-search-wrapper {
  input[id='geo-search-field'] {
    padding: 10px;
  }
}

@media (max-width: variables.$mobile_max_width) {
  .searchbar-col .input-inner span {
    font-size: variables.$default_font_size;
    line-height: 38px;
    display: inline-block;
  }
  .button-icon-lens {
    height: 48px;
    > img {
      display: none;
    }
  }

  .searchbar {
    .input {
      margin-bottom: var(--sbt-spacing-sm);
    }

    .input-icon {
      height: 48px;
      padding: var(--sbt-spacing-sm);
      width: 48px;
    }
    .input-label {
      display: none;
    }
  }
}

@media (min-width: variables.$tablet_min_width) {
  .searchbar {
    margin-bottom: var(--sbt-layout-sm);
    display: flex;
    gap: var(--sbt-spacing-xs);
    justify-content: space-between;
  }

  .searchbar-col {
    width: auto;
    flex: 1 0;
  }
  .searchbar-col-lens {
    width: 64px;
    display: flex;
    align-items: flex-end;
  }
  .searchbar .input {
    margin-bottom: 0;
  }
  .button-icon-lens > span {
    display: none;
  }
}

@media (min-width: variables.$desktop_min_width) {
  .searchbar {
    border-radius: var(--sbt-corner-md);
    box-shadow: var(--sbt-shadow-pop);
    margin-bottom: var(--sbt-layout-md);
    padding: var(--sbt-spacing-lg) var(--sbt-spacing-md);
  }

  .button-icon-lens {
    width: 64px;
    display: block;

    > span {
      display: none;
    }
  }
}

@media (max-width: variables.$tablet_max_width) {
  .main-category-selection {
    padding-left: var(--sbt-spacing-3xs);
  }

  #main-location-root div[class^='classes_icon'] {
    width: 48px;
  }

  #main-location-root input[type='search'] {
    padding-left: var(--sbt-spacing-3xs);
  }
}

@media (max-width: variables.$mobile_max_width) {
  .geo-search-container {
    margin-bottom: var(--sbt-spacing-sm);
  }
}
