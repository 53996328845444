@use '~@sbt-web/ui/common/css/variables';

.macro-category h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: var(--sbt-spacing-2xs);
}

.internal-links-links {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: var(--sbt-spacing-xs);

  .links-group {
    margin-bottom: var(--sbt-spacing-2xs);
  }

  a {
    color: var(--sbt-primary-text-color);

    &:hover {
      text-decoration: underline;
    }
  }
}

a.internal-links-brand {
  display: block;
  margin-bottom: var(--sbt-spacing-2xs);
  font-weight: 600;
}

.cat-lavoro {
  color: var(--sbt-accent-green);
}

.cat-market {
  color: var(--sbt-accent-yellow);
}

.cat-immobili {
  color: var(--sbt-accent-purple);
}

.cat-motori {
  color: var(--sbt-accent-blue);
}
